import GlobalStyle from '@gameonsports/components/lib/Global'
import Icons from '@gameonsports/components/lib/Icons'
import Loader from '@gameonsports/components/lib/Loader'
import {
  Redirect,
  RouteComponentProps,
  Router,
  useLocation,
} from '@reach/router'
import { openDB } from 'idb'
import React, { Suspense, lazy } from 'react'
import Helmet from 'react-helmet'
import { createGlobalStyle } from 'styled-components'
import { useRegisterSW } from 'virtual:pwa-register/react'
import Appcues from '../../components/Appcues/Appcues'
import BodyContext from '../../components/BodyContext'
import BrowserSupport from '../../components/BrowserSupport'
import ConfirmModal from '../../components/ConfirmModal'
import { Provider as GameDbProvider } from '../../components/GameDatastoreContext/GameDatastoreContext'
import PageErrorBoundaryContainer from '../../components/PageErrorBoundary'
import { Provider as LockProvider } from '../../components/SessionContext'
import useFeatureFlagOn from '../../hooks/useFeatureFlagOn'
import useOffline from '../../hooks/useOffline'
import usePlayerNumberDisabled from '../../hooks/usePlayerNumberDisabled'
import useToggle from '../../hooks/useToggle'
import { LiveScoringProvider } from '../../providers/LiveScoringProvider/LiveScoringProvider'
import { matchPath } from '../../utils/router'

/**
 * In the old data structure it was simple to view the raw event log for a game,
 * since the new one is a map, the devtools are less helpful sometimes since the ordering is wrong
 * This is a lofi way to have equivalent functionality
 *
 * Testers/devs can write _eventLog(<gameID>) in the console to view the eventlog.
 * If you're in a game yo can write _eventLog()
 *
 * Ideally we'd have a dev UI which shows debug info like this in a more presentable way.
 */
export function _eventLog(gameID?: string) {
  if (!gameID) {
    const splits = window.location.pathname.split('/')
    const i = splits.findIndex(g => g === 'games')
    if (i !== -1) {
      gameID = splits[i + 1]
    }
  }

  if (!gameID) {
    // eslint-disable-next-line no-console
    console.log('could not determine gameID')
    return
  }

  indexedDB.databases().then(e => {
    if (e.map(e => e.name).includes(gameID)) {
      openDB(gameID!).then(db => {
        db.getAllFromIndex('events', 'timestamp')
          .then(ev => {
            // eslint-disable-next-line no-console
            console.log(ev)
            db.close()
          })
          .catch(e => {
            console.error(e)
            db.close()
          })
      })
    }
  })
}

// @ts-ignore
window._eventLog = _eventLog

const NotFound = lazy(() => import('../404' /* webpackChunkName: "404" */))
const Login = lazy(() => import('../Login' /* webpackChunkName: "Login" */))
const Logout = lazy(() => import('../Logout' /* webpackChunkName: "Logout" */))
const Signup = lazy(() => import('../Signup' /* webpackChunkName: "Signup" */))
const NewSession = lazy(
  () => import('../NewSession' /* webpackChunkName: "NewSession" */),
)
const NewSessionV2 = lazy(
  () => import('../NewSessionV2' /* webpackChunkName: "NewSessionV2" */),
)
const TenantSelection = lazy(
  () => import('../TenantSelection' /* webpackChunkName: "TenantSelection" */),
)
const Session = lazy(
  () => import('../Session' /* webpackChunkName: "Session" */),
)

const GameHolder = lazy(
  () => import('../../pages/GameHolder' /* webpackChunkName: "GameHolder" */),
)

const Maintenance = lazy(
  () => import('../../pages/Maintenance' /* webpackChunkName: "Maintenance" */),
)

const MfaSetupRequired = lazy(
  () =>
    import(
      '../../pages/MfaSetupRequired' /* webpackChunkName: "MfaSetupRequired" */
    ),
)

const GAME_HOLDER_PATH = '/session/:date/:courtId/games/:gameId'

const GlobalTooltipStyles = createGlobalStyle`
  .react-tooltip {
    z-index: 9;
  }
`

const Authenticated: React.FunctionComponent<RouteComponentProps> = ({
  location,
}) => {
  const isSessionSetupUpdateOn = true

  return (
    <Appcues location={location}>
      <GameDbProvider>
        <LockProvider>
          <LiveScoringProvider>
            <Suspense fallback={<Loader />}>
              <Router>
                {process.env.REACT_APP_TENANT_SELECTOR === 'true' ? (
                  <TenantSelection path="/" />
                ) : isSessionSetupUpdateOn ? (
                  <NewSessionV2 path="/" />
                ) : (
                  <NewSession path="/" />
                )}
                <Session path="/session/*" />
                <GameHolder path={GAME_HOLDER_PATH} />
                <MfaSetupRequired path="/security/add-mfa" />
                <NotFound default />
              </Router>
            </Suspense>
          </LiveScoringProvider>
        </LockProvider>
      </GameDbProvider>
    </Appcues>
  )
}

const Unauthenticated: React.FunctionComponent<RouteComponentProps> = () => {
  const isLayupKillLogin = useFeatureFlagOn('kill-login')

  return (
    <>
      <PageErrorBoundaryContainer>
        <Suspense fallback={<Loader />}>
          <Router>
            {isLayupKillLogin ? (
              <>
                <Maintenance path="login" />
                <Maintenance path="signup/:token" />
              </>
            ) : (
              <>
                <Login path="login" />
                <Signup path="signup/:token" />
              </>
            )}
            <Logout path="logout" />
            <NotFound default />
          </Router>
        </Suspense>
      </PageErrorBoundaryContainer>
    </>
  )
}

const App = () => {
  usePlayerNumberDisabled() // This is required to save the value in cache
  const [overflowHidden, toggleOverflowHidden] = useToggle(false)
  const location = useLocation()
  const isOffline = useOffline()
  const {
    needRefresh: [needRefresh, setNeedRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    onRegisteredSW(swUrl, r) {
      // eslint-disable-next-line no-console
      console.log(`Service Worker at: ${swUrl}`)

      // Initial check on load
      r &&
        setTimeout(() => {
          r.update()
        }, 1000)

      // Check every 5 mins
      r &&
        setInterval(() => {
          r.update()
        }, 1000 * 60 * 5)
    },
    onRegisterError(error) {
      // eslint-disable-next-line no-console
      console.log('SW registration error', error)
    },
  })

  const refresh = () => {
    updateServiceWorker(true).then(() => {
      setNeedRefresh(false)
    })
  }

  if (
    'serviceWorker' in navigator === false ||
    'indexedDB' in window === false
  ) {
    return (
      <>
        <GlobalStyle background="white400" noHeader />
        <BrowserSupport />
      </>
    )
  }

  return (
    <BodyContext.Provider value={{ overflowHidden, toggleOverflowHidden }}>
      <GlobalStyle
        background="white400"
        noHeader
        overflowHidden={overflowHidden}
      />
      <GlobalTooltipStyles />
      <Helmet titleTemplate="%s | PlayHQ" defaultTitle="PlayHQ" />
      <Router>
        <Authenticated path="*" />
        {process.env.REACT_APP_TENANT_SELECTOR === 'true' ? (
          <Redirect from="auth/*" to="/" />
        ) : (
          <Unauthenticated path="auth/*" />
        )}
        <NotFound default />
      </Router>
      <Icons />
      {needRefresh &&
        !isOffline &&
        !matchPath(location.pathname, GAME_HOLDER_PATH) && (
          <ConfirmModal
            title="New version available"
            description="A new version of the electronic scoring application is available. Please select Update to update to the latest version."
            confirmButtonLabel="Update"
            onConfirm={() => refresh()}
          />
        )}
    </BodyContext.Provider>
  )
}

export default App
